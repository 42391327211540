
<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data === null">
	<span class="panel-sub-title">
		No Data
	</span>
</div>

<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data != null">
	<ul>
		<li class="section-container ui-g ui-g-5 no-padding">
			<span class="data-value">
				<p-divider align="center">
					<div>
						<span class="panel-sub-title">
							Direct
						</span>
					</div>
				</p-divider>
				<div class="data-set ui-g-12 no-padding"
					*ngIf="context.data.accounting != null">
					<div class="ellipsis-text">
						{{context.data.accounting.directWrittenPremium  | currency}}
					</div>
					<div class="ellipsis-text">
						{{context.data.accounting.directWrittenFees | currency}}
					</div>
					<div class="ellipsis-text">
						{{context.data.accounting.directWrittenTaxes | currency}}
					</div>
					<div class="ellipsis-text">
						{{context.data.accounting.directWrittenTotal | currency}}
					</div>
				</div>
			</span>
		</li>
		<li class="section-container ui-g ui-g-2 no-padding">
			<span class="data-value">
				<div class="data-labels ui-g-12 no-padding"
					*ngIf="context.data.accounting != null">
					<div class="ellipsis-text">
						<strong>Premium</strong>
					</div>
					<div class="ellipsis-text">
						<strong>Fees</strong>
					</div>
					<div class="ellipsis-text">
						<strong>Taxes</strong>
					</div>
					<div class="ellipsis-text">
						<strong>Total</strong>
					</div>
				</div>
			</span>
		</li>
		<li class="section-container ui-g ui-g-5 no-padding">
			<span class="data-value">
				<p-divider align="center">
					<div>
						<span class="panel-sub-title">
							Annualized
						</span>
					</div>
				</p-divider>
				<div class="data-set ui-g-12 no-padding"
					*ngIf="context.data.accounting != null">
					<div class="ellipsis-text">
						{{context.data.accounting.annualizedPremium | currency}}
					</div>
					<div class="ellipsis-text">
						{{context.data.accounting.annualizedFees | currency}}
					</div>
					<div class="ellipsis-text">
						{{context.data.accounting.annualizedTaxes | currency}}
					</div>
					<div class="ellipsis-text">
						{{context.data.accounting.annualizedTotal | currency}}
					</div>
				</div>
			</span>
		</li>
	</ul>
</div>