<div class="utility-menu-container"
	clickOutside
	(clickOutside)="clickOutside($event)"
	[contentDisplayed]="overlayDrawerItem != null">

	<div class="utility-menu">

		<div class="global-utilities"
			[@drawerAnimation]="overlayDrawerItem != null">
			<p-scrollPanel>
				<div class="global-utility-scroll-content">
					<ul class="utility-list"
						*ngFor="let drawerMenuItem of globalUtilities">
						<li class="utility-list-item"
							[ngClass]="{
								'active': (drawerMenuItem.active === true),
								'pinned': (drawerMenuItem.pinned === true)
							}"
							[pTooltip]="drawerMenuItem.label"
							mobileTooltip
							tooltipPosition="left"
							tooltipStyleClass="utility-menu-tooltip"
							(click)="utilityMenuItemClick(drawerMenuItem)">
							<ng-template [ngIf]="drawerMenuItem.badgeItem == null"
								[ngIfElse]="BadgeIcon">
								<i [class]="iconClass(drawerMenuItem)">
								</i>
							</ng-template>
							<ng-template #BadgeIcon>
								<i [class]="iconClass(drawerMenuItem)"
									pBadge
									severity="drawerMenuItem.badgeItem.messageLevel"
									value="drawerMenuItem.badgeItem.count">
								</i>
							</ng-template>
						</li>
					</ul>
				</div>
			</p-scrollPanel>
		</div>

		<div class="context-utilities"
			[@drawerAnimation]="overlayDrawerItem != null">
			<p-scrollPanel>
				<div class="context-utility-scroll-content">
					<ul class="utility-list"
						*ngFor="let drawerMenuItem of contextUtilities">
						<li #TooltipItem
							class="utility-list-item"
							[ngClass]="{
								'active': (drawerMenuItem.active === true),
								'pinned': (drawerMenuItem.pinned === true),
								'disabled': (drawerMenuItem.disabled === true)
							}"
							[pTooltip]="drawerMenuItem.label + (drawerMenuItem.disabled === true ? ' - ' + (drawerMenuItem.tooltipOptions?.tooltipLabel || 'Disabled') : '')"
							mobileTooltip
							tooltipPosition="left"
							tooltipStyleClass="utility-menu-tooltip"
							(click)="preventDefault($event)"
							(tap)="utilityMenuItemClick(drawerMenuItem)">
							<ng-template [ngIf]="drawerMenuItem.state?.badgeItem == null
								|| drawerMenuItem.state.badgeItem.count === 0"
								[ngIfElse]="BadgeIcon">
								<i [class]="iconClass(drawerMenuItem)">
								</i>
							</ng-template>
							<ng-template #BadgeIcon>
								<i [class]="iconClass(drawerMenuItem)"
									pBadge
									[severity]="drawerMenuItem.state.badgeItem.messageLevel"
									[value]="drawerMenuItem.state.badgeItem.count">
								</i>
							</ng-template>
						</li>
					</ul>
				</div>
			</p-scrollPanel>
		</div>

	</div>

	<app-drawers
		[pageContext]="pageContext"
		[overlayDrawerItem]="overlayDrawerItem"
		[pinnedDrawerItems]="pinnedDrawerItems"
		(drawerCloseButtonClicked)="utilityMenuItemClick($event)"
		(drawerPinButtonClicked)="pinDrawerClick($event)">
	</app-drawers>

</div>