<ng-template [ngIf]="field.props.useCurrency === true"
	[ngIfElse]="NumberInput">
	<p-inputNumber
		[formControl]="formControl"
		[formlyAttributes]="field"
		[inputStyleClass]="inputStyleClass"
		[min]="field.props.min"
		[max]="field.props.max"
		[step]="field.props.multipleOf"
		[showButtons]="field.props.multipleOf != null"
		mode="currency"
		currency="USD"
		locale="en-US"
		(onInput)="onChange($event)"
		(onBlur)="onBlur()">
	</p-inputNumber>
</ng-template>
<ng-template #NumberInput>
	<p-inputNumber
		[formControl]="formControl"
		[formlyAttributes]="field"
		[inputStyleClass]="inputStyleClass"
		mode="decimal"
		[min]="field.props.min"
		[max]="field.props.max"
		[step]="field.props.multipleOf || 1"
		[showButtons]="true"
		[minFractionDigits]="field.props.minFractionDigits || 0"
		[maxFractionDigits]="field.props.maxFractionDigits || 0"
		[useGrouping]="field.props.useGrouping !== false"
		(onInput)="onChange($event)"
		(onBlur)="onBlur()">
	</p-inputNumber>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>