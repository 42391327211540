<label class="visually-hidden"
	[attr.for]="'AriaInput' + id">
	{{field.props.label}}
</label>

<ng-template
	[ngIf]="(field.props.actionMenuItems == null || field.props.actionMenuItems.length === 0) && (field.props.addonMenuItems == null || field.props.addonMenuItems.length === 0)"
	[ngIfElse]="InputGroup">
	<p-dropdown
		[options]="dataOptions"
		[formControl]="formControl"
		[formlyAttributes]="field"
		[styleClass]="inputStyleClass"
		[placeholder]="field.props.placeholder"
		[ariaLabelledBy]="'Filter ' + field.props.label"
		[inputId]="'AriaInput' + id"
		[appendTo]="field.props.appendTo"
		[showClear]="field.props.showClear"
		[group]="field.props.group"
		[emptyFilterMessage]="field.props.emptyFilterMessage || 'No results found'"
		[filter]="true"
		optionLabel="label"
		optionValue="value"
		filterBy="label"
		filterPlaceholder="Filter"
		[autoDisplayFirst]="false"
		(onChange)="change($event)"
		(onFilter)="onFilter($event)">
	</p-dropdown>
</ng-template>

<ng-template #InputGroup>
	<div class="p-grid">
		<div #InputGroupContainer
			class="p-inputgroup">
			<span *ngFor="let addonMenuItem of field.props.addonMenuItems"
				class="p-inputgroup-addon">
				<i [class]="addonMenuItem.getCustomIcon(formControl.value)"
					[style]="addonMenuItem.getCustomStyle(formControl.value)">
				</i>
			</span>
			<span class="dropdown-group"
				[ngStyle]="{'width': calculatedFieldWidth}">
				<p-dropdown
					[options]="dataOptions"
					[formControl]="formControl"
					[formlyAttributes]="field"
					[styleClass]="inputStyleClass"
					[placeholder]="field.props.placeholder"
					[ariaLabelledBy]="'Filter ' + field.props.label"
					[inputId]="'AriaInput' + id"
					[appendTo]="field.props.appendTo"
					[showClear]="field.props.showClear"
					[group]="field.props.group"
					[emptyFilterMessage]="field.props.emptyFilterMessage || 'No results found'"
					[filter]="true"
					optionLabel="label"
					optionValue="value"
					filterBy="label"
					filterPlaceholder="Filter"
					[autoDisplayFirst]="false"
					(onChange)="change($event)"
					(onFilter)="onFilter($event)">
				</p-dropdown>
			</span>
			<span class="menu-item-wrapper">
				<span *ngFor="let actionMenuItem of field.props.actionMenuItems">
					<button #ActionMenuItems
						type="button"
						pButton pRipple
						[ngStyle]="{'max-width': actionMenuItem.maxWidth}"
						[label]="actionMenuItem.label"
						[icon]="actionMenuItem.icon"
						styleClass="p-button-warn"
						[disabled]="actionMenuItem.disabled"
						[loading]="actionMenuItem.loading"
						(click)="executeActionMenuItemCommand($event, actionMenuItem)">
					</button>
				</span>
			</span>
		</div>
	</div>

	<span *ngIf="!loadingActionItems"
		clickOutside
		(clickOutside)="closeEllipsisMenu()"
		[contentDisplayed]="activeEllipsisMenu">
		<div class="ellipsis-menu {{siteLayoutService.contentCssClass}}"
			[ngStyle]="{
				'left': ellipsisMenuOffsetLeft,
				'top': ellipsisMenuOffsetTop
			}"
			[@displayAnimation]="activeEllipsisMenu ? 'visible' : 'hidden'">
			<p-scrollPanel styleClass="ellipsis-menu-container">
				<operation-menu
					#OperationMenu
					displayName="additional actions"
					class="layout-menu"
					id="ellipsis-menu"
					[initialModel]="ellipsisActionItems"
					[reset]="resetEllipsisMenu"
					[pageContext]="field.props.context"
					(closeOverlays)="closeEllipsisMenu()">
				</operation-menu>
			</p-scrollPanel>
		</div>
	</span>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>