/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	CdkVirtualScrollViewport
} from '@angular/cdk/scrolling';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	Type,
	ViewChild
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IFormlyDefinitionsNew
} from '@shared/interfaces/application-objects/formly-definitions.interface';
import {
	ITableExpandDefinition
} from '@shared/interfaces/application-objects/table-expand-definition.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	get
} from 'lodash-es';
import {
	LazyLoadEvent,
	MenuItem
} from 'primeng/api';
import {
	Subject,
	Subscription,
	debounceTime,
	from
} from 'rxjs';

/* eslint-enable max-len */

@Component(
	{
		selector: 'app-common-table',
		templateUrl: './common-table.component.html',
		styleUrl: './common-table.component.scss',
		animations: [
			ContentAnimation
		]
	})

/**
 * A component representing an instance of the common table component.
 *
 * @export
 * @class CommonTableComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
export class CommonTableComponent
implements OnInit
{
	/**
	 * Initializes a new instance of the common table component.
	 *
	 * @param {EntityService} entityService
	 * The entity service used for table action authorization checks.
	 * @param {SiteLayoutService} siteLayoutService
	 * Service utilized to catch any site layout change and
	 * site layout information.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector reference for this component.
	 * @param {Location} resolver
	 * The resolver object.
	 * @memberof CommonTableComponent
	 */
	public constructor(
		public entityService: EntityService,
		public siteLayoutService: SiteLayoutService,
		public changeDetectorReference: ChangeDetectorRef,
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets or sets the formly field.
	 *
	 * @type {FormlyFieldConfig}
	 * @memberof CommonTableComponent
	 */
	@Input() public field!: FormlyFieldConfig;

	/**
	 * Gets or sets the field context.
	 *
	 * @type {object[]}
	 * @memberof EntitySelectComponent
	 */
	 @Input() public fieldContext?: any = [];

	/**
	 * Gets or sets the table definitions data required to
	 * generate the table display within it's own functionality.
	 *
	 * @type {ICommonTable}
	 * @memberof CommonTableComponent
	 */
	@Input() public tableDefinitions!: ICommonTable;

	/**
	 * Gets or sets the unique identifier of each row, this value defaults
	 * to 'id'.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	@Input() public rowIdentifier: string = 'id';

	/**
	 * Gets or sets the selected rows ids.
	 *
	 * @type {object[]}
	 * @memberof EntitySelectComponent
	 */
	@Input() public selectedItems?: object[] = [];

	/**
	 * Gets or sets a value signifying whether or not the keyword based messsage
	 * should be displayed if zero keywords are found.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	@Input() public displayKeywordMessage: boolean = false;

	/**
	 * Gets or sets the finished loading event.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof EntitySelectComponent
	 */
	@Output() public fireFormlyChangeDetection: EventEmitter<void> =
		new EventEmitter;

	/**
	 * Gets or sets the row selected event.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof EntitySelectComponent
	 */
	@Output() public itemSelected: EventEmitter<string> = new EventEmitter;

	/**
	 * Gets or sets the virtual scroll viewport.
	 *
	 * @type {CdkVirtualScrollViewport}
	 * @memberof CommonTableComponent
	 */
	@ViewChild(CdkVirtualScrollViewport)
	public viewport!: CdkVirtualScrollViewport;

	/**
	 * Gets or sets the overall page container.
	 *
	 * @type {HTMLDivElement}
	 * @memberof CommonTableComponent
	 */
	@ViewChild('TableContainer')
	public tableContainer!: HTMLDivElement;

	/**
	 * Gets or sets the table title element.
	 *
	 * @type {HTMLDivElement}
	 * @memberof CommonTableComponent
	 */
	@ViewChild('TableTitle')
	public tableTitle!: HTMLDivElement;

	/**
	 * Gets or sets the table summary element.
	 *
	 * @type {HTMLDivElement}
	 * @memberof CommonTableComponent
	 */
	@ViewChild('TableSummary')
	public tableSummary!: HTMLDivElement;

	/**
	 * Gets or sets the layout changed subject which is called
	 * when the site layout sizes are changed in this component.
	 *
	 * @type {Subject<void>}
	 * @memberof CommonTableComponent
	 */
	public layoutChanged: Subject<void> = new Subject<void>();

	/**
	 * Gets or sets the common table based page context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof CommonTableComponent
	 */
	public pageContext!:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the parent component context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof CommonTableComponent
	 */
	public customContext!:
		IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the initial load complete flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public initialLoadComplete: boolean = false;

	/**
	 * Gets or sets the table fully loaded data flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public tableFullyLoaded: boolean = false;

	/**
	 * Gets or sets the loading table data flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public loadingTableData: boolean = true;

	/**
	 * Gets or sets the loading additional data flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public loadingNextDataset: boolean = false;

	/**
	 * Gets or sets the table data.
	 *
	 * @type {any[]}
	 * @memberof CommonTableComponent
	 */
	public tableData: any[] = [];

	/**
	 * Gets or sets the virtual data displayed in the viewport.
	 *
	 * @type {any[]}
	 * @memberof CommonTableComponent
	 */
	public virtualData: any[] = [];

	/**
	 * Gets or sets the tables current index.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public currentIndex: number = 0;

	/**
	 * Gets or sets the dynamic table height based on virtual page size,
	 * explicit table height, or viewport height.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public tableHeight: string = '0px';

	/**
	 * Gets or sets the number of rows to reserve when displaying a drawer. If
	 * fewer rows than this are shown, the table will match the required height
	 * while displaying the sidebar.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public minimumDrawerItemHeight: number = 5;

	/**
	 * Gets or sets the explicit height of each table row. This value also
	 * exists in the scss file and should be kept in sync.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public rowHeight: number = 50;

	/**
	 * Gets or sets the selected item.
	 *
	 * @type {any}
	 * @memberof CommonTableComponent
	 */
	public selectedItem: any;

	/**
	 * Gets or sets the visible value of the sidebar.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public sidebarVisible: boolean = false;

	/**
	 * Gets or sets the visible value of the settings sidebar.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public settingsVisible: boolean = false;

	/**
	 * Gets or sets the visible value of the create sidebar.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public createVisible: boolean = false;

	/**
	 * Gets or sets the visible value of the filter panel.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public filterVisible: boolean = false;

	/**
	 * Gets or sets the expand title.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public expandTitle: string;

	/**
	 * Gets or sets the expand actions.
	 *
	 * @type {MenuItem[]}
	 * @memberof CommonTableComponent
	 */
	public expandActions: MenuItem[];

	/**
	 * Gets or sets the value signifying whether expand actions are displayed.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public displayingExpandActions: boolean = true;

	/**
	 * Gets or sets the value defining whether or not row actions exists in this
	 * table.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public rowActionsExist: boolean = false;

	/**
	 * Gets or sets the value defining whether or not
	 * the user is allowed navigation
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public navigationHighlighting: boolean = true;

	/**
	 * Gets or sets the sidebar display mode when displaying a selected item.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public displayMode: string = 'View';

	/**
	 * Gets or sets the definitions used when displaying dynamic formly
	 * layouts in the sidebar.
	 *
	 * @type {IFormlyDefinitionsNew}
	 * @memberof CommonTableComponent
	 */
	public formlyDefinitions: IFormlyDefinitionsNew;

	/**
	 * Gets or sets the dynamic component view in the sidebar when pointing
	 * to a specific component sidebar display.
	 *
	 * @type {Type<any>}
	 * @memberof CommonTableComponent
	 */
	public dynamicComponent: Type<any>;

	/**
	 * Gets or sets the columns to display.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof CommonTableComponent
	 */
	public visibleColumns: ICommonTableColumn[];

	/**
	 * Gets or sets the number of items to display in this list before
	 * suggesting the user filters their search.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public filterResultsSuggestionCount: number = 500;

	/**
	 * Gets or sets the settings applied value, which signifies that
	 * selected settings should override dynamic height and width
	 * displays.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof CommonTableComponent
	 */
	private settingsApplied: boolean = false;

	/**
	 * Gets or sets the table data api promise.
	 *
	 * @type {(objectSearch: IObjectSearch | IEntitySearch) => Promise<any[]>}
	 * @memberof CommonTableComponent
	 */
	private apiPromise!: Promise<any>;

	/**
	 * Gets or sets the data subscription used in this component.
	 *
	 * @type {Subscription}
	 * @memberof CommonTableComponent
	 */
	private dataSubscription!: Subscription;

	/**
	 * Gets the string used to identify or display the 'px' value.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly pixelIdentifier: string = 'px';

	/**
	 * Gets the string used to identify the sidebar element displayed in this
	 * table.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly sidebarClassIdentifier: string = '.p-sidebar';

	/**
	 * Gets the width required to begin including additional
	 * columns in the display. This uses math.ceiling() so a target size
	 * of 360 (Our minimum target size) will display two columns.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly additionalColumnRequiredWidth: number = 225;

	/**
	 * Gets the minimum column display width in characters.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly minimumColumnCharacterWidth: number = 15;

	/**
	 * Gets the maximum column display width in characters.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly maximumColumnCharacterWidth: number = 45;

	/**
	 * Gets the reserved action item display width in characters.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly actionItemWidth: number = 2.25;

	/**
	 * Gets the table height when displaying a table without data.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly headerOnlyHeight: number = 52.5;

	/**
	 * Gets the display order used for the action column.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly actionColumnDisplayOrder: number = 10000;

	/**
	 * Gets the delay used before updating the sidebar position and style
	 * following the sidebar open action.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly sidebarUpdateDelay: number =
		AppConstants.time.oneHundredMilliseconds;

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables
	 * or loading has completed.
	 *
	 * @memberof CommonTableComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.layoutChanged.next();
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close settings displayed drawe
	 * when another overlay is displayed.
	 *
	 * @memberof CommonTableComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(): void
	{
		if (this.settingsVisible === true)
		{
			this.toggleSettingsDisplay();
		}

		if (this.createVisible === true)
		{
			this.toggleCreateDisplay();
		}
	}

	/**
	 * On initialization event.
	 * Sets the table and column and settings definitions
	 * for this object list view, as well as loading the data
	 * from the server to be displayed within the table.
	 *
	 * @async
	 * @memberof CommonTableComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.resetTableData();

		this.pageContext =
			<IDynamicComponentContext<CommonTableComponent, any>>
			{
				source: this,
				data: {}
			};

		this.setTableItemIdentifier();
		this.setSelectedColumns();
		this.setCommonTableContext();
		this.setRowActionsExist();
		this.setNavigationHighlighting();

		this.layoutChanged.pipe(
			debounceTime(this.siteLayoutService.debounceDelay))
			.subscribe(() =>
			{
				if (AnyHelper.isNull(this.tableDefinitions))
				{
					return;
				}

				this.displayTable();
				this.setSelectedColumns();
			});

		await this.loadTableData(
			{
				first: 0,
				rows: this.tableDefinitions.objectSearch.virtualPageSize
			});
	}

	/**
	 * Handles the on destroy interface.
	 * This completes any watched subjects to free memory.
	 *
	 * @memberof CommonTableComponent
	 */
	public ngOnDestroy(): void
	{
		this.layoutChanged.complete();
	}

	/**
	 * Refreshes the expand actions display when the set of actions are
	 * altered outside of this component.
	 *
	 * @memberof CommonTableComponent
	 */
	public updateExpandActions(): void
	{
		this.displayingExpandActions = false;

		setTimeout(
			() =>
			{
				this.displayingExpandActions = true;
			});
	}

	/**
	 * Gets the table column data based on the provided item and data key.
	 *
	 * @param {any} item
	 * The item to retrieve the data from.
	 * @param {string} dataKey
	 * The data key used to retrieve the data from the item.
	 * @param {string} dataFunction
	 * The data function used to transform the data retrieved from the item.
	 * this value defaults to null.
	 * @returns {string}
	 * The table column data.
	 * @memberof CommonTableComponent
	 */
	public getTableColumnData(
		item: any,
		dataKey: string,
		dataFunction: string = null): string
	{
		let dataValue: string =
			get(
				item,
				dataKey);

		if (!AnyHelper.isNullOrEmpty(dataFunction))
		{
			dataValue =
				StringHelper.transformToFunction(
					dataFunction,
					this.pageContext)(dataValue);
		}

		return dataValue;
	}

	/**
	 * Gets the row selected value of the current item.
	 *
	 * @param {any} item
	 * The item to check row selection for.
	 * @returns {boolean}
	 * The selected value of the table item.
	 * @memberof CommonTableComponent
	 */
	public isItemSelected(item: any): boolean
	{
		if (AnyHelper.isNull(this.tableDefinitions.itemSelection))
		{
			return false;
		}

		return this.tableDefinitions.itemSelection.selectionMode ===
			AppConstants.itemSelectionMode.single
			? !AnyHelper.isNull(this.selectedItem)
				&& this.selectedItem[this.rowIdentifier] ===
					item[this.rowIdentifier]
			: !AnyHelper.isNull(
				this.selectedItems.find(
					(selectedRow: any) =>
						selectedRow[this.rowIdentifier] ===
							item[this.rowIdentifier]));
	}

	/**
	 * Handles the selection of an item within the table.
	 *
	 * @async
	 * @param {any} item
	 * The item to select.
	 * @param {string} action
	 * The action to take when selecting the item. This value defaults
	 * to view mode.
	 * @param {any} event
	 * The event that triggered the selection.
	 * @memberof CommonTableComponent
	 */
	public async selectItem(
		item: any = null,
		action: string = AppConstants.displayMode.view,
		event: any = null): Promise<void>
	{
		event?.stopImmediatePropagation();
		event?.preventDefault();
		this.displayTable();

		if (AnyHelper.isNullOrEmpty(item))
		{
			return;
		}

		if (this.shouldToggleSelectedItem(item))
		{
			return;
		}

		this.selectedItem =
			{
				...item
			};

		this.displayMode = action;

		this.itemSelected.emit(
			this.tableDefinitions
				.itemSelection
				?.capturedSelectedItemEventPromise);

		if (!AnyHelper.isNullOrWhitespace(
			this.tableDefinitions
				.itemSelection
				?.capturedSelectedItemEventPromise))
		{
			return;
		}

		if (this.shouldHandleItemSelection())
		{
			return;
		}

		if (AnyHelper.isNull(this.tableDefinitions.actions))
		{
			return;
		}

		if (await this.shouldHandleItemDrillInAction())
		{
			return;
		}

		if (await this.shouldHandleItemCreationCommandString())
		{
			return;
		}

		if (await this.shouldRunViewOrUpdateCommands())
		{
			return;
		}

		await this.configureSelectedItemDisplayMode();
	}

	/**
	 * Handles the update index action if set in table definitions.
	 *
	 * @async
	 * @param {any} item
	 * The item to update the index of.
	 * @param {string} updateDirection
	 * The update direction ('Up' or 'Down').
	 * @param {any} event
	 * The event that triggered the index update.
	 * @memberof CommonTableComponent
	 */
	public updateIndex(
		item: any,
		updateDirection: string,
		event: any): void
	{
		event?.stopImmediatePropagation();
		event?.preventDefault();

		if (updateDirection === 'Up')
		{
			this.tableDefinitions.actions.updateIndex[0]
				.command(item);

			return;
		}

		this.tableDefinitions.actions.updateIndex[1]
			.command(item);
	}

	/**
	 * If additional selected item data is set in the table expand definition,
	 * this will run the datapromise or function to populate that addition
	 * data needed at the expand level. This will be added directly to the
	 * data object.
	 *
	 * @param {ITableExpandDefinition} tableExpandDefinition
	 * The table expand definition currently being shown.
	 * @returns {Promise<any>}
	 * A set of additional data to be added to the selected item.
	 * @memberof CommonTableComponent
	 */
	public async setAdditionalSelectedItemData(
		tableExpandDefinition: ITableExpandDefinition): Promise<any>
	{
		const additionalSelectedItemDataPromise: any =
			tableExpandDefinition.setAdditionalSelectedItemData;

		if (!AnyHelper.isNullOrEmpty(additionalSelectedItemDataPromise))
		{
			if(tableExpandDefinition.useAdditionalSelectedItemDataPromise
				=== true)
			{
				await StringHelper
					.transformToFieldDataPromise(
						StringHelper.interpolate(
							additionalSelectedItemDataPromise,
							this.pageContext),
						this.pageContext,
						this.field);
			}
			else
			{
				await additionalSelectedItemDataPromise();
			}
		}
	}

	/**
	 * Handles the closing of the sidebar action and displays the table.
	 *
	 * @memberof CommonTableComponent
	 */
	public displayTable(): void
	{
		this.sidebarVisible = false;
		this.settingsVisible = false;
		this.createVisible = false;
		this.filterVisible = false;

		this.displayMode = AppConstants.displayMode.view;
		this.expandActions = [];
		this.dynamicComponent = null;

		this.setTableDimensions();
		this.fireChanges();
	}

	/**
	 * Adds a selected item recently created to the table data. This method
	 * will handle custom view model decorations and scroll to the
	 * newly created item.
	 *
	 * @memberof CommonTableComponent
	 */
	public async addSelectedItem(): Promise<void>
	{
		if (AnyHelper.isFunction(this.tableDefinitions.decorateViewModel))
		{
			this.selectedItem =
				await this.tableDefinitions.decorateViewModel(
					this.selectedItem);
		}

		this.tableData =
			[
				this.selectedItem,
				...this.tableData
			];
		this.virtualData =
			[
				this.selectedItem,
				...this.virtualData.slice(
					0,
					this.tableDefinitions.objectSearch.limit - 1)
			];

		this.viewport.scrollToIndex(0);
		this.displayTable();
	}

	/**
	 * Updates the selected item within the table data. This method will
	 * handle custom view model decorations and update the table display.
	 *
	 * @memberof CommonTableComponent
	 */
	public async updateSelectedItem(): Promise<void>
	{
		if (AnyHelper.isFunction(this.tableDefinitions.decorateViewModel))
		{
			this.selectedItem =
				await this.tableDefinitions.decorateViewModel(
					this.selectedItem);
		}

		this.tableData =
			this.tableData.map(
				(item: any) =>
				{
					if (get(item, this.rowIdentifier) ===
						get(this.selectedItem, this.rowIdentifier))
					{
						return this.selectedItem;
					}

					return item;
				});

		this.virtualData =
			this.virtualData.map(
				(item: any) =>
				{
					if (get(item, this.rowIdentifier) ===
						get(this.selectedItem, this.rowIdentifier))
					{
						return this.selectedItem;
					}

					return item;
				});

		this.displayTable();
	}

	/**
	 * Deletes the selected item from the table data. This method will
	 * update the table display.
	 *
	 * @memberof CommonTableComponent
	 */
	public deleteSelectedItem(): void
	{
		this.tableData =
			this.tableData.filter(
				(item: any) =>
					get(item, this.rowIdentifier) !==
						get(this.selectedItem, this.rowIdentifier));

		this.virtualData =
			this.virtualData.filter(
				(item: any) =>
					get(item, this.rowIdentifier) !==
						get(this.selectedItem, this.rowIdentifier));

		this.displayTable();
	}

	/**
	 * Toggles the display of the create item sidebar.
	 *
	 * @memberof CommonTableComponent
	 */
	public toggleCreateDisplay(): void
	{
		if (this.createVisible === true)
		{
			this.displayTable();

			return;
		}

		this.displayTable();

		setTimeout(
			() =>
				this.selectItem(
					{
						id: 0
					},
					AppConstants.displayMode.create),
			AppConstants.time.quarterSecond);
	}

	/**
	 * Toggles the display of the settings sidebar.
	 *
	 * @memberof CommonTableComponent
	 */
	public toggleSettingsDisplay(): void
	{
		if (this.settingsVisible === true)
		{
			this.displayTable();

			return;
		}

		this.displayTable();

		setTimeout(
			() =>
			{
				this.initializeSettingsDisplay();

				this.sidebarVisible = true;
				this.settingsVisible = true;
				this.expandTitle = 'Settings';

				this.setTableDimensions();
				this.fireChanges();

				setTimeout(
					() => this.updateSidebarLocation(),
					this.sidebarUpdateDelay);
			},
			AppConstants.time.quarterSecond);
	}

	/**
	 * Applies the settings selections to the table display.
	 *
	 * @async
	 * @memberof CommonTableComponent
	 */
	public async applySettings(): Promise<void>
	{
		this.tableDefinitions.objectSearch.virtualPageSize =
			this.selectedItem.virtualPageSize;
		this.tableDefinitions.selectedColumns =
			this.tableDefinitions.availableColumns
				.filter(
					(column: ICommonTableColumn) =>
						this.selectedItem.visibleColumns
							.includes(column.dataKey));
		this.settingsApplied = true;

		await this.ngOnInit();

		this.displayTable();
	}

	/**
	 * Handles the quick filter displayed event to set the flag for this
	 * being currently visible.
	 *
	 * @async
	 * @memberof CommonTableComponent
	 */
	public quickFilterDisplayed(
		displayed: boolean): void
	{
		if (this.filterVisible === true)
		{
			this.displayTable();

			return;
		}

		this.displayTable();

		setTimeout(
			() =>
			{
				this.filterVisible = displayed;
				this.fireChanges();
			},
			AppConstants.time.quarterSecond);
	}

	/**
	 * Applies the filter criteria event to the table display. This will set
	 * the object search filter to the provided filter value.
	 *
	 * @async
	 * @param {string} filter
	 * The filter event sent from a table quick filter or formatted in a
	 * component.
	 * @memberof CommonTableComponent
	 */
	public async filterCriteriaChanged(
		filter: string): Promise<void>
	{
		this.tableDefinitions.objectSearch.filter = filter;

		if (!AnyHelper.isNull(
			this.tableDefinitions.actions.filter?.quickFilters))
		{
			this.tableDefinitions.actions.filter.selectedFilterValue = filter;
		}

		await this.ngOnInit();

		this.displayTable();
	}

	/**
	 * Scrolls the table to the bottom of the viewport.
	 *
	 * @memberof CommonTableComponent
	 */
	public scrollTableToTop(): void
	{
		this.viewport.scrollToIndex(0);
	}

	/**
	 * Scrolls the table to the bottom of the viewport.
	 *
	 * @memberof CommonTableComponent
	 */
	public scrollTableToBottom(): void
	{
		this.viewport.scrollToIndex(
			this.virtualData.length);
	}

	/**
	 * Handles scroll events sent from the viewport to check if the last item
	 * is currently displayed and additional data loads should occur.
	 *
	 * @async
	 * @memberof CommonTableComponent
	 */
	public async checkIfLastItem(
		index: number): Promise<void>
	{
		this.currentIndex = index;

		const additionalItemsExist: boolean =
			(this.virtualData.length /
				this.tableDefinitions.objectSearch.limit) % 1 === 0;

		if (additionalItemsExist
			&& this.loadingTableData === false
			&& this.loadingNextDataset === false
			&& (index + this.tableDefinitions.objectSearch.virtualPageSize)
				>= this.virtualData.length - 1)
		{
			this.loadingNextDataset = true;

			this.tableDefinitions.objectSearch.offset +=
				this.tableDefinitions.objectSearch.limit;

			await this.loadTableData(
				{
					first: this.tableDefinitions.objectSearch.offset,
					rows: this.tableDefinitions.objectSearch.virtualPageSize
				});

			this.loadingNextDataset = false;

			this.viewport.scrollToIndex(
				this.currentIndex);
		}
	}

	/**
	 * Validates the expand component changes.
	 *
	 * @async
	 * @param {boolean} isValid
	 * Determines when a expan component change is valid.
	 * @memberof CommonTableComponent
	*/
	public async validExpandComponentChanged(
		isValid: boolean): Promise<void>
	{
		this.expandActions?.forEach(
			(action: MenuItem) =>
			{
				action.disabled = !isValid;
			});

		// Allow delete statement logic to handle secondary disables.
		if (this.displayMode === AppConstants.displayMode.delete
			&& !AnyHelper.isNull(
				this.tableDefinitions.actions?.delete?.deleteStatement)
			&& AnyHelper.isFunction(
				this.tableDefinitions.actions?.delete?.deleteStatement))
		{
			await this.tableDefinitions.actions
				.delete.deleteStatement();
		}
	}

	/**
	 * Fires the change detection for the formly form to update the view.
	 *
	 * @memberof CommonTableComponent
	 */
	public fireChanges(): void
	{
		this.changeDetectorReference.detectChanges();
		this.fireFormlyChangeDetection.emit();
	}

	/**
	 * Sets the common table context in the parent component's table
	 * definition.
	 *
	 * @memberof CommonTableComponent
	 */
	private setCommonTableContext(): void
	{
		if (!AnyHelper.isNullOrEmpty(
			this.tableDefinitions.commonTableContext))
		{
			this.tableDefinitions.commonTableContext(this.pageContext);
		}

		// This value is used as a fallback when no common table context
		// method exists.
		this.tableDefinitions.getCommonTableContext =
			() => this.pageContext;
	}

	/**
	 * Sets the common table identifier if this is different than the
	 * default of id.
	 *
	 * @memberof CommonTableComponent
	 */
	private setTableItemIdentifier(): void
	{
		if (AnyHelper.isNullOrWhitespace(this.tableDefinitions.dataKey))
		{
			return;
		}

		this.rowIdentifier = this.tableDefinitions.dataKey;
	}

	/**
	 * Sets the row actions exist flag that signifies whether the row should
	 * be selectable.
	 *
	 * @memberof CommonTableComponent
	 */
	private setRowActionsExist(): void
	{
		this.rowActionsExist =
			this.tableDefinitions.actions?.view != null
				|| this.tableDefinitions.itemSelection != null
				|| this.tableDefinitions.actions?.drillIn != null;
	}

	/**
	 * Sets the allowed navigation flag that signifies whether the row should
	 * be selectable.
	 *
	 * @memberof CommonTableComponent
	 */
	private setNavigationHighlighting(): void
	{
		const currentUser: IUser =
			this.resolver.resolveCurrentUser();

		this.navigationHighlighting =
			!AnyHelper.isNullOrWhitespace(
				this.tableDefinitions.navigationAccess)
				? SecurityHelper
					.membershipExists(
						this.tableDefinitions.navigationAccess,
						currentUser)
				: true;
	}

	/**
	 * Initializes the settings layout and actions for the settings sidebar.
	 *
	 * @memberof CommonTableComponent
	 */
	private initializeSettingsDisplay(): void
	{
		this.selectedItem =
			{
				visibleColumns:
					this.visibleColumns
						.filter(
							(column: ICommonTableColumn) =>
								column.displayOrder <
									this.actionColumnDisplayOrder)
						.map(
							(column: ICommonTableColumn) =>
								column.dataKey),
				virtualPageSize:
					this.tableDefinitions.objectSearch.virtualPageSize
			};

		const settingsLayout: FormlyFieldConfig[] =
			[
				{
					key: 'visibleColumns',
					type: FormlyConstants.customControls
						.customMultiSelect,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						label: 'Selected Columns',
						placeholder: 'Choose Columns',
						required: true,
						appendTo: FormlyConstants.appendToTargets.body,
						options:
							this.tableDefinitions.availableColumns.map(
								(column: ICommonTableColumn) =>
									<IDropdownOption>
									{
										label: column.columnHeader,
										value: column.dataKey
									}),
					}
				},
				{
					key: 'virtualPageSize',
					type: FormlyConstants.customControls.customInputNumber,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Results Set Count',
						required: true,
						multipleOf: 5
					},
					validators: {
						validPageSize: {
							expression:
								(control: UntypedFormControl) =>
									control.value <=
										this.tableDefinitions
											.objectSearch.limit,
							message:
								'A maximum of '
									+ this.tableDefinitions.objectSearch.limit
									+ ' items can be displayed.'
						}
					}
				}
			];

		this.formlyDefinitions =
			{
				definition: {},
				layout: settingsLayout
			};

		this.expandActions =
			[
				<MenuItem>
				{
					label: 'Apply',
					id: 'ApplySettings',
					styleClass: 'ui-button-primary',
					command: () =>
						this.applySettings()
				}
			];
	}

	/**
	 * Loads the initial set of table data based on the provided lazy load
	 * event. This method when complete will set initialLoadComplete to true.
	 *
	 * @async
	 * @param {LazyLoadEvent} lazyLoadEvent
	 * The lazy load event used to load the table data.
	 * @memberof CommonTableComponent
	 */
	private async loadTableData(
		lazyLoadEvent: LazyLoadEvent): Promise<void>
	{
		this.loadingTableData = true;

		if (this.dataSubscription != null)
		{
			this.dataSubscription.unsubscribe();
		}

		if (this.displayKeywordMessage === true)
		{
			this.initialLoadComplete = true;
			this.tableFullyLoaded = true;
			this.loadingTableData = false;
			this.tableData = [];
			this.virtualData = [];

			return new Promise(
				(resolve) => resolve());
		}

		return new Promise(
			(resolve) =>
			{
				this.tableDefinitions.objectSearch.offset =
					lazyLoadEvent.first;

				this.apiPromise =
					!AnyHelper.isNullOrEmpty(this.tableDefinitions.apiPromise)
						? this.tableDefinitions.apiPromise(
							this.tableDefinitions.objectSearch)
						: StringHelper
							.transformToFieldDataPromise(
								StringHelper.interpolate(
									this.tableDefinitions.apiPromiseString,
									this.pageContext),
								this.pageContext,
								this.field);

				this.dataSubscription =
					from(this.apiPromise)
						.subscribe(
							(data: any[]) =>
							{
								if (data.length <
									this.tableDefinitions
										.objectSearch.limit)
								{
									this.tableFullyLoaded = true;
								}

								this.tableData =
									[
										...this.tableData,
										...data
									];

								this.virtualData =
									[
										...this.virtualData,
										...data
									];

								if (this.initialLoadComplete === false)
								{
									this.initialLoadComplete = true;
									this.fireChanges();

									this.setTableDimensions();
								}

								this.loadingTableData = false;
								this.fireChanges();

								resolve();
							});
			});
	}

	/**
	 * Sets the table dimensions based on the current site layout and
	 * table definitions.
	 *
	 * @memberof CommonTableComponent
	 */
	private setTableDimensions(): void
	{
		this.calculateTableHeight();
		this.calculateColumnWidths();
	}

	/**
	 * Resets the table and virtual data and sets this as a not loaded table.
	 * Note: This is called in NgOnInit for when the table data is reloaded
	 * by a filter change or table settings are applied.
	 *
	 * @memberof CommonTableComponent
	 */
	private resetTableData(): void
	{
		this.tableData = [];
		this.virtualData = [];
		this.loadingTableData = true;
		this.tableFullyLoaded = false;
		this.loadingNextDataset = false;
		this.initialLoadComplete = false;
	}

	/**
	 * Calculates the table height based on the current site layout and
	 * table definitions.
	 *
	 * @memberof CommonTableComponent
	 */
	private calculateTableHeight(): void
	{
		// Include the header row.
		let calculatedItemCount: number =
			this.virtualData.length + 1;

		// Handle dynamic height calculations if stand alone.
		if (this.tableDefinitions.nestedTable !== true)
		{
			const pageTitleHeight: number =
				DocumentHelper.getPageHeaderHeight() ?? 0;

			const reservedSpace: number =
				pageTitleHeight
					+ ((<any>this.tableTitle)?.nativeElement
						.getBoundingClientRect()
						.height ?? 0)
					+ ((<any>this.tableSummary)?.nativeElement
						.getBoundingClientRect()
						.height ?? 0)
					+ (AppConstants.staticLayoutSizes.nestedContentPadding * 2)
					+ (this.tableDefinitions.fullPageReservedHeight ?? 0);

			calculatedItemCount =
				Math.floor(
					(this.siteLayoutService.displayTabletView === true
						? this.siteLayoutService.windowHeight
							- AppConstants.staticLayoutSizes.mobileHeaderHeight
							- reservedSpace
						: this.siteLayoutService.windowHeight
							- reservedSpace)
							/ this.rowHeight);
		}

		// Override height calculations if settings are applied.
		// Include the table header in the height calculation.
		const displayableTableItems: number =
			this.settingsApplied === true
				? this.tableDefinitions.objectSearch.virtualPageSize + 1
				: calculatedItemCount;

		if (this.sidebarVisible === true
			&& this.isBelowMinimumSidebarHeight())
		{
			this.tableHeight =
				`${this.rowHeight
					* (this.minimumDrawerItemHeight
						+ 1)}${this.pixelIdentifier}`;
		}
		else if (this.tableDefinitions.tableHeight
			?.virtualPageSizeBased !== false
			&& this.virtualData.length <=
				this.tableDefinitions.objectSearch
					.virtualPageSize)
		{
			this.tableHeight =
				this.virtualData.length > 0
					? `${this.rowHeight *
						(Math.min(
							this.virtualData.length + 1,
							displayableTableItems))}`
						+ `${this.pixelIdentifier}`
					: this.headerOnlyHeight + this.pixelIdentifier;
		}
		else
		{
			this.setTableHeight(
				Math.min(
					this.tableDefinitions.objectSearch
						.virtualPageSize + 1,
					displayableTableItems));
		}
	}

	/**
	 * Sets the height of the table for display to equal the row height times
	 * the provided virtualPageSize or as the explicit table height if set.
	 *
	 * @param {number} itemCount
	 * The item count used to calculate the table height
	 * @memberof CommonTableComponent
	 */
	private setTableHeight(
		itemCount: number): void
	{
		const calculatedTableHeight: number =
			itemCount === 0
				? this.headerOnlyHeight
				: this.rowHeight * itemCount;

		this.tableHeight =
			(this.tableDefinitions.tableHeight?.virtualPageSizeBased !== false)
				? `${calculatedTableHeight}px`
				: `${this.tableDefinitions.tableHeight.explicitHeight}px`;
	}

	/**
	 * Calculates the column widths based on the current site layout and
	 * table definitions.
	 *
	 * @memberof CommonTableComponent
	 */
	private calculateColumnWidths(): void
	{
		const visibleColumns: ICommonTableColumn[] =
			[
				...this.visibleColumns.filter(
					(column: ICommonTableColumn) =>
						column.displayOrder < this.actionColumnDisplayOrder)
			];

		const dataLengthArray: number[] =
			this.getDataLengthArray(visibleColumns);

		const actionItemCount: number =
			this.getActionItemCount();
		const displayActionColumn: boolean =
			this.tableData.length > 0
				&& actionItemCount > 0;
		const nestedTableWeight: number =
			(this.tableDefinitions.nestedTable === true
				? 3 * (1/actionItemCount)
				: 1);
		const standardColumnWidth: number =
			(actionItemCount * this.actionItemWidth)
				* nestedTableWeight;

		const reservedColumnWidth: number =
			this.getReservedColumnWidth(
				standardColumnWidth);

		const sum: number =
			dataLengthArray.reduce(
				(accumulator, currentValue) =>
					accumulator + currentValue,
				0);
		const proportionalActionColumnWidth: number =
			Math.min(
				(displayActionColumn
					? reservedColumnWidth
					: 0),
				sum / (this.siteLayoutService.contentWidth
					< AppConstants.layoutBreakpoints.smallPhone
					? 3
					: 4));
		const weight: number =
			100 / (sum + proportionalActionColumnWidth);
		let totalWidth: number = 0;

		for (const column of visibleColumns)
		{
			const widthPercent: number =
				Math.floor(
					dataLengthArray[visibleColumns.indexOf(column)]
						* weight);

			column.width =
				`${widthPercent}%`;
			totalWidth += widthPercent;
		}

		if (displayActionColumn === true)
		{
			visibleColumns.push(
				<ICommonTableColumn>
				{
					displayOrder: this.actionColumnDisplayOrder,
					width: `${100 - totalWidth}%`
				});
		}

		this.visibleColumns =
			[
				...visibleColumns
			];
	}

	/**
	 * Determines the action item count for table row actions.
	 *
	 * @returns {number}
	 * The number of action items to display.
	 * @memberof CommonTableComponent
	 */
	private getActionItemCount(): number
	{
		const displayEdit: number =
			AnyHelper.isNull(
				this.tableDefinitions.actions
					?.update)
				? 0
				: 1;
		const displayDelete: number  =
			AnyHelper.isNull(
				this.tableDefinitions.actions
					?.delete)
				? 0
				: 1;
		const displayEllipsis: number  =
			AnyHelper.isNull(
				this.tableDefinitions.actions
					?.rowLevelEllipsis)
				? 0
				: 1;
		const displayUpdateIndex: number  =
			AnyHelper.isNull(
				this.tableDefinitions.actions
					?.updateIndex)
				? 0
				: 1;

		return displayEdit
			+ displayDelete
			+ displayEllipsis
			+ displayUpdateIndex;
	}

	/**
	 * Gets the reserved action column width based on the current site layout
	 * and standard column width.
	 *
	 * @param {number} standardColumnWidth
	 * The standard column width used to calculate the reserved column width.
	 * @returns {number}
	 * The reserved column width.
	 * @memberof CommonTableComponent
	 */
	private getReservedColumnWidth(
		standardColumnWidth: number): number
	{
		let reservedColumnWidth: number;
		switch (true)
		{
			case this.siteLayoutService.contentWidth
				< AppConstants.layoutBreakpoints.smallPhone:
				reservedColumnWidth =
					6 * standardColumnWidth;
				break;
			case this.siteLayoutService.contentWidth
				< AppConstants.layoutBreakpoints.tablet:
				reservedColumnWidth =
					3.75 * standardColumnWidth;
				break;
			case this.siteLayoutService.contentWidth
				< AppConstants.layoutBreakpoints.desktop:
				reservedColumnWidth =
					3 * standardColumnWidth;
				break;
			case this.siteLayoutService.contentWidth
				< AppConstants.layoutBreakpoints.largeDesktop:
				reservedColumnWidth =
					2.25 * standardColumnWidth;
				break;
			default:
				reservedColumnWidth =
					1.5 * standardColumnWidth;
				break;
		}

		return reservedColumnWidth;
	}

	/**
	 * Gets the data length array based on the provided visible columns.
	 *
	 * @param {ICommonTableColumn[]} visibleColumns
	 * The visible columns used to calculate the data length array.
	 * @returns {number[]}
	 * The data length array.
	 * @memberof CommonTableComponent
	 */
	private getDataLengthArray(
		visibleColumns: ICommonTableColumn[]): number[]
	{
		const dataLengthArray: number[] = [];
		for (const column of visibleColumns)
		{
			if (this.tableData.length === 0)
			{
				dataLengthArray.push(
					column.columnHeader?.toString().length ?? 0);
			}
			else
			{
				const columnHeaderLength: number =
					column.columnHeader
						?.toString().length
						?? 0;
				dataLengthArray.push(
					Math.max(
						...this.tableData.map(
							(item: any) =>
							{
								// Handle shortened dates from ISO date strings
								// and icon based displays.
								const columnDataLength: number =
									column.dataFormatType ===
										AppConstants.dataFormatTypes.date
										|| column.dataFormatType ===
											AppConstants.dataFormatTypes.icon
										? this.minimumColumnCharacterWidth
										: item[column.dataKey]
											?.toString().length
											?? 0;

								return Math.min(
									Math.max(
										Math.max(
											columnDataLength,
											columnHeaderLength),
										this.minimumColumnCharacterWidth),
									this.maximumColumnCharacterWidth);
							})));
			}
		}

		return dataLengthArray;
	}

	/**
	 * Determines if the table is below the minimum sidebar height.
	 *
	 * @returns {boolean}
	 * True if the table is below the minimum sidebar height, otherwise false.
	 * @memberof CommonTableComponent
	 */
	private isBelowMinimumSidebarHeight(): boolean
	{
		const belowMinimumVirtualHeight: boolean =
			this.tableDefinitions.tableHeight?.virtualPageSizeBased !== false
				&& (this.tableDefinitions.objectSearch
					.virtualPageSize < this.minimumDrawerItemHeight
					|| this.virtualData
						.length < this.minimumDrawerItemHeight);
		const belowMinimumExplicitHeight: boolean =
			!AnyHelper.isNullOrWhitespace(
				this.tableDefinitions.tableHeight?.explicitHeight)
				&& parseFloat(
					this.tableHeight.replace(
						this.pixelIdentifier,
						AppConstants.empty))
						< (this.minimumDrawerItemHeight * this.rowHeight);

		return belowMinimumVirtualHeight || belowMinimumExplicitHeight;
	}

	/**
	 * Gets the set of columns defined and sets up the columns
	 * that will be displayed currently in this object list table.
	 * Note: If this is set via settings, the selected columns will
	 * not reset until the user changes the settings.
	 *
	 * @memberof CommonTableComponent
	 */
	private setSelectedColumns(): void
	{
		const columnDisplayCount: number =
			Math.ceil(
				this.siteLayoutService.contentWidth
					/ this.additionalColumnRequiredWidth);

		if (this.settingsApplied === false)
		{
			this.visibleColumns =
				this.tableDefinitions.availableColumns
					.slice(
						0,
						columnDisplayCount);
		}
		else
		{
			this.visibleColumns =
				this.tableDefinitions.selectedColumns;
		}

		this.setTableDimensions();
	}

	/**
	 * Updates the sidebar location and style based on the current
	 * table title rectangle and site layout.
	 *
	 * @memberof CommonTableComponent
	 */
	private updateSidebarLocation(): void
	{
		const drawerElement: HTMLElement =
			(<any>this.tableContainer).nativeElement
				.querySelector(this.sidebarClassIdentifier);

		const displayFullScreenView: boolean =
			this.siteLayoutService.displayTabletView === true
				|| this.tableDefinitions.nestedTable === true;

		if (displayFullScreenView === true)
		{
			drawerElement.style.borderTopLeftRadius = '0px';
			drawerElement.style.borderBottomLeftRadius = '0px';
		}

		const tableTitleHeight: number = 38.5;
		const drawerWidthPercentage: number =
			displayFullScreenView === true
				? 100
				: 90;

		drawerElement.style.top =
			tableTitleHeight
				+ this.pixelIdentifier;
		drawerElement.style.right =
			0
				+ this.pixelIdentifier;
		drawerElement.style.width =
			`calc(${drawerWidthPercentage}%`;
		drawerElement.style.height =
			`calc(100% - ${tableTitleHeight}px)`;
	}

	/**
	 * Configures the selected item display mode based on the current
	 * display mode.
	 *
	 * @async
	 * @memberof CommonTableComponent
	 */
	private async configureSelectedItemDisplayMode(): Promise<void>
	{
		let expandDefinition: ITableExpandDefinition;

		switch(this.displayMode)
		{
			case AppConstants.displayMode.create:
				expandDefinition = this.tableDefinitions.actions.create;
				break;
			case AppConstants.displayMode.update:
				expandDefinition = this.tableDefinitions.actions.update;
				break;
			case AppConstants.displayMode.delete:
				expandDefinition = this.tableDefinitions.actions.delete;
				break;
			default:
				expandDefinition = this.tableDefinitions.actions.view;
				break;
		}

		if (AnyHelper.isNull(expandDefinition))
		{
			return;
		}

		this.formlyDefinitions =
			{
				definition:
					expandDefinition.definition,
				layout:
					expandDefinition.layout,
				additionalLayout:
					expandDefinition.additionalLayout
			};
		this.dynamicComponent =
			expandDefinition.component;
		this.expandActions =
			expandDefinition.items;
		this.customContext =
			expandDefinition.customContext;

		if (this.displayMode !== AppConstants.displayMode.delete)
		{
			await this.setAdditionalSelectedItemData(
				expandDefinition);
		}
		else
		{
			this.formlyDefinitions.layout =
				<FormlyFieldConfig[]>
				[
					{
						type: FormlyConstants.customControls
							.customMessage,
						props: {
							message:
								await this.tableDefinitions.actions
									.delete.deleteStatement()
						}
					}
				];
		}

		this.expandTitle =
			(AnyHelper.isFunction(<any>this.tableDefinitions.expandTitle))
				? (<Function>this.tableDefinitions.expandTitle)()
				: this.tableDefinitions.expandTitle;

		this.sidebarVisible = true;
		this.createVisible =
			this.displayMode === AppConstants.displayMode.create;
		this.setTableDimensions();
		this.fireChanges();

		setTimeout(
			() => this.updateSidebarLocation(),
			this.sidebarUpdateDelay);
	}

	/**
	 * Determines if the view or update commands should be run based on the
	 * current table definitions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * True if the view or update commands should be run, otherwise false.
	 * @memberof CommonTableComponent
	 */
	private async shouldRunViewOrUpdateCommands(): Promise<boolean>
	{
		if (this.displayMode === AppConstants.displayMode.view
			&& this.tableDefinitions.actions?.view
				?.disabledExpandItem === true)
		{
			await this.tableDefinitions.actions
				.view.items[0].command();

			return true;
		}

		if (this.displayMode === AppConstants.displayMode.update
			&& this.tableDefinitions.actions?.update
				?.disabledExpandItem === true)
		{
			await this.tableDefinitions.actions
				.update.items[0].command();

			return true;
		}

		return false;
	}

	/**
	 * Determines if the item creation command string should be run based on
	 * the current table definitions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * True if the item creation command string should be run, otherwise false.
	 * @memberof CommonTableComponent
	 */
	private async shouldHandleItemCreationCommandString(): Promise<boolean>
	{
		if (this.displayMode === AppConstants.displayMode.create
			&& !AnyHelper.isNullOrEmpty(
				this.tableDefinitions.actions?.create?.commandString))
		{
			await StringHelper
				.transformToFieldDataPromise(
					StringHelper.interpolate(
						this.tableDefinitions.actions
							.create.commandString,
						this.pageContext),
					this.pageContext,
					this.field);

			return true;
		}

		return false;
	}

	/**
	 * Determines if the item drill in action should be run based on the
	 * current table definitions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * True if the item drill in action should be run, otherwise false.
	 * @memberof CommonTableComponent
	 */
	private async shouldHandleItemDrillInAction(): Promise<boolean>
	{
		if (this.displayMode === AppConstants.displayMode.view
			&& !AnyHelper.isNull(
				this.tableDefinitions.actions?.drillIn))
		{
			const drillInCommand: void | Promise<any> =
				!AnyHelper.isNullOrEmpty(
					this.tableDefinitions.actions.drillIn.command)
					? this.tableDefinitions.actions.drillIn.command({})
					: StringHelper
						.transformToFieldDataPromise(
							StringHelper.interpolate(
								this.tableDefinitions.actions
									.drillIn.commandString,
								this.pageContext),
							this.pageContext,
							this.field);

			await drillInCommand;

			return true;
		}

		return false;
	}

	/**
	 * Determines if the item selection should be handled based on the
	 * current table definitions.
	 *
	 * @returns {boolean}
	 * True if the item selection should be handled, otherwise false.
	 * @memberof CommonTableComponent
	 */
	private shouldHandleItemSelection(): boolean
	{
		if (this.displayMode === AppConstants.displayMode.view
			&& AnyHelper.isFunction(
				this.tableDefinitions.itemSelection?.selectedItemEvent))
		{
			if (this.tableDefinitions.itemSelection.selectionMode ===
				AppConstants.itemSelectionMode.single)
			{
				this.tableDefinitions.itemSelection.selectedItemEvent(
					this.selectedItem,
					false);
			}

			this.tableDefinitions.itemSelection.selectedItemEvent(
				this.selectedItem,
				true);

			return true;
		}

		return false;
	}

	/**
	 * Determines if the selected item should be toggled based on the
	 * current table definitions.
	 *
	 * @param {any} item
	 * The item to toggle the selected item for.
	 * @returns {boolean}
	 * True if the selected item should be toggled, otherwise false.
	 * @memberof CommonTableComponent
	 */
	private shouldToggleSelectedItem(
		item: any): boolean
	{
		if (!AnyHelper.isNull(
			this.tableDefinitions.itemSelection)
			&& this.tableDefinitions.itemSelection.selectionMode ===
				AppConstants.itemSelectionMode.single
			&& !AnyHelper.isNull(this.selectedItem)
			&& item[this.rowIdentifier] ===
				this.selectedItem[this.rowIdentifier])
		{
			this.selectedItem = null;

			this.itemSelected.emit(
				this.tableDefinitions
					.itemSelection
					?.capturedSelectedItemEventPromise);

			return true;
		}

		return false;
	}
}