
<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data?.entityInstance?.id === null">
	<span class="panel-sub-title">
		No Data
	</span>
</div>
<div class="list-item-container ui-g ui-g-12 no-padding">
	<ul>
		<ng-template
			[ngIf]="(data?.length || 0) > 0"
			ngFor
			let-item
			[ngForOf]="data">
			<li *ngIf="item.type != null"
				class="section-container">
				<span class="data-value">
					<p-divider align="left">
						<div>
							<span class="fa"
								[ngClass]="{
									'fa-home': item.type === 'Dwelling'
									}">
							</span>
							<span class="panel-sub-title">
								{{item.type}}
							</span>
						</div>
					</p-divider>
					<ng-template [ngIf]="item.type === 'Dwelling'"
						[ngIfElse]="Vehicle">
						<span class="data-value">
							<span *ngIf="item.characteristics.addresses != null"
								class='font-italic'>
								Address:
								<span *ngIf="item.characteristics.addresses[0] != null">
									<span *ngIf="item.characteristics.addresses[0].address != null
										&& item.characteristics.addresses[0].address != ''">
										{{item.characteristics.addresses[0].address}},
									</span>
									<span *ngIf="item.characteristics.addresses[0].city != null
										&& item.characteristics.addresses[0].city != ''">
										{{item.characteristics.addresses[0].city}},
									</span>
									{{item.characteristics.addresses[0].state}}
									{{item.characteristics.addresses[0].postalCode}}
								</span>
								<br />
							</span>
							<strong>Coverages:</strong>
							<ul class="square-bullets">
								<li>Personal Property: {{item.characteristics.limits.personalProperty}}</li>
								<li>Additional Living Expense: {{item.characteristics.limits.additionalLivingExpense}}</li>
								<li>Personal Liability: {{item.characteristics.limits.personalLiability}}</li>
								<li>Medical Payment To Others: {{item.characteristics.limits.medicalPaymentsToOthers}}</li>
							</ul>
							<strong>Deductibles:</strong>
							<ul class="square-bullets">
								<li>AOP: {{item.characteristics.deductibles.aop}}</li>
								<li>Wind Hail: {{item.characteristics.deductibles.deductibleWindHail}}</li>
							</ul>
						</span>
					</ng-template>
					<ng-template #Vehicle>
						<span class="data-value">
							Vehicle Placeholder
						</span>
					</ng-template>
				</span>
				<br />
			</li>
		</ng-template>

	</ul>
</div>