<ng-template
	[ngIf]="loading === true"
	[ngIfElse]="Content">
	<div class="ui-g-12 no-horizontal-padding text-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<ng-template #Content>
	<div class="ui-g-12 payment-content {{siteLayoutService.contentCssClass}}">
		<div class="ui-g-12 no-padding">
			<div class="ui-g-12 no-padding header-section">
				<div class="panel-sub-title">
					Installment Details
				</div>
				<div class="due-date"
					*ngIf="nextPayment?.date != null">
					{{nextPayment.date | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat:'L/d/yy'}}
				</div>
			</div>

			<div class="ui-g-12 initial-section">
				<div class="data-labels ui-g-9 no-padding">
					<div class="ellipsis-text">
						Premium
					</div>
					<div class="ellipsis-text">
						Fees
					</div>
					<div class="ellipsis-text">
						Taxes
					</div>
					<div class="ellipsis-text">
						Total Amount
					</div>
				</div>
				<div class="data-set ui-g-3 no-padding">
					<div class="ellipsis-text">
						{{(nextPayment?.installmentWrittenPremium ?? 0) | currency}}
					</div>
					<div class="ellipsis-text">
						{{(nextPayment?.installmentWrittenFees ?? 0)| currency}}
					</div>
					<div class="ellipsis-text">
						{{(nextPayment?.installmentWrittenTaxes ?? 0)| currency}}
					</div>
					<div class="ellipsis-text">
						{{((nextPayment?.installmentWrittenPremium
							+ nextPayment?.installmentWrittenFees
							+ nextPayment?.installmentWrittenTaxes) ?? 0)| currency}}
					</div>
				</div>
			</div>

			<p-divider>
			</p-divider>

			<div class="ui-g-12 operational-fees-container">
				<div class="ui-g-12 no-padding operational-fee-section"
					*ngFor="let item of invoicedOperationalFees">
					<div class="data-labels ui-g-9 no-padding">
						<div class="ellipsis-text">
							{{item.name}}
						</div>
					</div>
					<div class="data-set ui-g-3 no-padding">
						<div class="ellipsis-text">
							{{item.amount | currency}}
						</div>
					</div>
				</div>
			</div>
			<div class="ui-g-12 operational-fee-total-section"
				[ngClass]="{
					'no-operational-fee-total-section':
						invoicedOperationalFees.length === 0
				}">
				<div class="data-labels ui-g-9 no-padding">
					<div class="ellipsis-text">
						Total Operational Fees
					</div>
				</div>
				<div class="data-set ui-g-3 no-padding">
					<div class="ellipsis-text">
						{{summedOperationalFees | currency}}
					</div>
				</div>
			</div>

			<p-divider>
			</p-divider>

			<div class="ui-g-12">
				<div class="data-labels ui-g-9 no-padding">
					<div class="ellipsis-text">
						Balance Forward
					</div>
					<div class="ellipsis-text">
						Adjustments
					</div>
					<div class="ellipsis-text">
						Payments
					</div>
					<div class="ellipsis-text">
						Other
					</div>
				</div>
				<div class="data-set ui-g-3 no-padding">
					<div class="ellipsis-text">
						{{totalTransferBalance | currency}}
					</div>
					<div class="ellipsis-text">
						{{summedAdjustments | currency}}
					</div>
					<div class="ellipsis-text">
						{{summedLedgerPayments | currency}}
					</div>
					<div class="ellipsis-text">
						{{summedOtherCredits | currency}}
					</div>
				</div>
			</div>

			<p-divider>
			</p-divider>

			<div class="ui-g-12 initial-section">
				<div class="data-labels ui-g-9 no-padding">
					<div class="ellipsis-text">
						Due
					</div>
					<div class="ellipsis-text">
						Remaining Balance
					</div>
				</div>
				<div class="data-set ui-g-3 no-padding">
					<div class="ellipsis-text">
						{{(nextPayment?.due ?? 0 + transferBalance) | currency}}
					</div>
					<div class="ellipsis-text">
						{{remainingBalance | currency}}
					</div>
				</div>
			</div>

		</div>
	</div>
</ng-template>