/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	CommonModule
} from '@angular/common';
import {
	NgModule
} from '@angular/core';
import {
	RouterModule
} from '@angular/router';
import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	InsurancePolicySearchComponent
} from '@insurance/components/insurance-policy-search/insurance-policy-search.component';
import {
	MakePaymentDetailsComponent
} from '@insurance/components/make-payment-details/make-payment-details.component';
import {
	StatusReasonsComponent
} from '@insurance/components/status-reasons/status-reasons.component';
import {
	SelectDisbursementOrganizationsComponent
} from '@insurance/components/wizard-steps/organization/commissions/select-disbursement-organizations/select-disbursement-organizations.component';
import {
	FortePaymentMethodComponent
} from '@insurance/components/wizard-steps/policy/add-payment-method/forte/forte-payment-method.component';
import {
	PaymentMethodCreateComponent
} from '@insurance/components/wizard-steps/policy/add-payment-method/payment-method-create.component';
import {
	PaymentMethodProcessorComponent
} from '@insurance/components/wizard-steps/policy/add-payment-method/payment-method-processor.component';
import {
	ProcessOnePaymentMethodComponent
} from '@insurance/components/wizard-steps/policy/add-payment-method/process-one/process-one-payment-method.component';
import {
	AdjustmentDetailsComponent
} from '@insurance/components/wizard-steps/organization/commissions/adjustment-details/adjustment-details.component';
import {
	AdjustmentSummaryComponent
} from '@insurance/components/wizard-steps/organization/commissions/adjustment-summary/adjustment-summary.component';
import {
	AdjustmentWriteOffAmountComponent
} from '@insurance/components/wizard-steps/policy/adjustment-write-off-amount/adjustment-write-off-amount.component';
import {
	AdjustmentWriteOffCommentsComponent
} from '@insurance/components/wizard-steps/policy/adjustment-write-off-comments/adjustment-write-off-comments.component';
import {
	BindTransactionComponent
} from '@insurance/components/wizard-steps/policy/bind-transaction/bind-transaction.component';
import {
	CreateTransactionComponent
} from '@insurance/components/wizard-steps/policy/create-transaction/create-transaction.component';
import {
	RefundConfirmationComponent
} from '@insurance/components/wizard-steps/policy/generate-refund/refund-confirmation.component';
import {
	MakePaymentReceiptComponent
} from '@insurance/components/wizard-steps/policy/make-payment-receipt/make-payment-receipt.component';
import {
	MakePaymentComponent
} from '@insurance/components/wizard-steps/policy/make-payment/make-payment.component';
import {
	NonRenewPolicyComponent
} from '@insurance/components/wizard-steps/policy/non-renew-policy/non-renew-policy.component';
import {
	PaymentBatchApproveComponent
} from '@insurance/components/wizard-steps/policy/payment-batch-approve/payment-batch-approve.component';
import {
	PaymentBatchCreateComponent
} from '@insurance/components/wizard-steps/policy/payment-batch-create/payment-batch-create.component';
import {
	PaymentDetailsComponent
} from '@insurance/components/wizard-steps/policy/payment-details/payment-details.component';
import {
	PaymentReversalConfirmationComponent
} from '@insurance/components/wizard-steps/policy/payment-reversal/payment-reversal-confirmation.component';
import {
	PaymentReversalSelectionComponent
} from '@insurance/components/wizard-steps/policy/payment-reversal/payment-reversal-selection.component';
import {
	RescindNonRenewPolicyComponent
} from '@insurance/components/wizard-steps/policy/rescind-non-renew-policy/rescind-non-renew-policy.component';
import {
	TermAlterPaymentPlanComponent
} from '@insurance/components/wizard-steps/policy/term-alter-payment-plan/term-alter-payment-plan.component';
import {
	TransactionCancelEffectiveDateComponent
} from '@insurance/components/wizard-steps/policy/transaction-cancel-effective-date/transaction-cancel-effective-date.component';
import {
	TransactionCancelReasonsComponent
} from '@insurance/components/wizard-steps/policy/transaction-cancel-reasons/transaction-cancel-reasons.component';
import {
	TransactionCancelReinstateCommentsComponent
} from '@insurance/components/wizard-steps/policy/transaction-cancel-reinstate-comments/transaction-cancel-reinstate-comments.component';
import {
	TransactionDeclineReasonsComponent
} from '@insurance/components/wizard-steps/policy/transaction-decline-reasons/transaction-decline-reasons.component';
import {
	TransactionEndorseIssueDifferencesComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-issue-differences/transaction-endorse-issue-differences.component';
import {
	TransactionEndorseIssueSummaryComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-issue-summary/transaction-endorse-issue-summary.component';
import {
	TransactionEndorseReasonsComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-reasons/transaction-endorse-reasons.component';
import {
	TransactionEndorseStatusEffectiveDateComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-status-effective-date/transaction-endorse-status-effective-date.component';
import {
	TransactionRescindCancelComponent
} from '@insurance/components/wizard-steps/policy/transaction-rescind-cancel/transaction-rescind-cancel.component';
import {
	TransactionRescindEndorseComponent
} from '@insurance/components/wizard-steps/policy/transaction-rescind-endorse/transaction-rescind-endorse.component';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	AssociatedEntityListComponent
} from '@insurance/dynamic-components/associated-entity-list/associated-entity-list.component';
import {
	AssociatedKeyContactsComponent
} from '@insurance/dynamic-components/associated-entity-list/associated-key-contacts/associated-key-contacts.component';
import {
	AssociatedOrganizationComponent
} from '@insurance/dynamic-components/associated-entity-list/associated-organization/associated-organization.component';
import {
	TransactionAccountingDetailsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-accounting-details/transaction-accounting-details.component';
import {
	TransactionAssetsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-assets/transaction-assets.component';
import {
	TransactionCoveragesDeductiblesComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-coverages-deductibles/transaction-coverages-deductibles.component';
import {
	TransactionFormsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-forms/transaction-forms.component';
import {
	TransactionInterestsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-interests/transaction-interests.component';
import {
	TransactionReasonsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-reasons/transaction-reasons.component';
import {
	CommissionDisbursementListExpandComponent
} from '@insurance/dynamic-components/commission-disbursement-list/commission-disbursement-list-expand/commission-disbursement-list-expand.component';
import {
	CommissionDisbursementListComponent
} from '@insurance/dynamic-components/commission-disbursement-list/commission-disbursement-list.component';
import {
	NextPaymentInstallmentDetailsComponent
} from '@insurance/dynamic-components/next-payment-installment-details/next-payment-installment-details.component';
import {
	InsuranceComponent
} from '@insurance/insurance.component';
import {
	InsuranceTokenLookup
} from '@insurance/insurance.token-lookup';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	SharedModule
} from '@shared/shared.module';

/* eslint-enable max-len */

@NgModule({
	declarations: [
		AdjustmentDetailsComponent,
		AdjustmentSummaryComponent,
		AdjustmentWriteOffAmountComponent,
		AdjustmentWriteOffCommentsComponent,
		AssociatedEntityListComponent,
		AssociatedKeyContactsComponent,
		AssociatedOrganizationComponent,
		InsuranceComponent,
		BindTransactionComponent,
		CommissionDisbursementListComponent,
		CommissionDisbursementListExpandComponent,
		CreateTransactionComponent,
		FortePaymentMethodComponent,
		InsurancePolicySearchComponent,
		MakePaymentComponent,
		MakePaymentDetailsComponent,
		MakePaymentReceiptComponent,
		NextPaymentInstallmentDetailsComponent,
		NonRenewPolicyComponent,
		PaymentBatchApproveComponent,
		PaymentBatchCreateComponent,
		PaymentDetailsComponent,
		PaymentMethodCreateComponent,
		PaymentMethodProcessorComponent,
		PaymentReversalConfirmationComponent,
		RefundConfirmationComponent,
		PaymentReversalSelectionComponent,
		ProcessOnePaymentMethodComponent,
		RescindNonRenewPolicyComponent,
		SelectDisbursementOrganizationsComponent,
		StatusReasonsComponent,
		StatusReasonsDirective,
		TransactionAccountingDetailsComponent,
		TransactionAssetsComponent,
		TransactionCancelEffectiveDateComponent,
		TransactionCancelReasonsComponent,
		TransactionCancelReinstateCommentsComponent,
		TransactionCoveragesDeductiblesComponent,
		TransactionFormsComponent,
		TransactionEndorseIssueDifferencesComponent,
		TransactionEndorseIssueSummaryComponent,
		TransactionEndorseReasonsComponent,
		TransactionInterestsComponent,
		TransactionReasonsComponent,
		TransactionRescindCancelComponent,
		TransactionRescindEndorseComponent,
		TransactionEndorseStatusEffectiveDateComponent,
		TransactionDeclineReasonsComponent,
		TermAlterPaymentPlanComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule.forChild([
			{
				path: 'insurance',
				component: InsuranceComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						AppConstants.securityGroups.users,
						AppConstants.securityGroups.administrators,
						AppConstants.securityGroups.supportUsers
					]
				},
				children: [
					{
						path: 'accessDenied',
						component: AccessDeniedComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'dashboard',
						component: GenericDashboardComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'dashboard/:dashboardType',
						component: GenericDashboardComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/view',
								component: GenericBasePageComponent,
								data: {
									refreshComponent: true
								}
							},
							{
								path: ':displayComponent/edit',
								component: GenericBasePageComponent,
								canActivate: [
									AppAuthGuard
								],
								data:
								{
									roles: [
										AppConstants.securityGroups
											.administrators,
										AppConstants.securityGroups
											.supportUsers
									],
									refreshComponent: true
								}
							}
						]
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	],
	exports: [
		AdjustmentDetailsComponent,
		AdjustmentSummaryComponent,
		AdjustmentWriteOffAmountComponent,
		AdjustmentWriteOffCommentsComponent,
		BindTransactionComponent,
		CommissionDisbursementListComponent,
		CommissionDisbursementListExpandComponent,
		CreateTransactionComponent,
		InsurancePolicySearchComponent,
		MakePaymentComponent,
		MakePaymentDetailsComponent,
		MakePaymentReceiptComponent,
		NextPaymentInstallmentDetailsComponent,
		NonRenewPolicyComponent,
		PaymentBatchApproveComponent,
		PaymentBatchCreateComponent,
		PaymentDetailsComponent,
		RescindNonRenewPolicyComponent,
		StatusReasonsComponent,
		SelectDisbursementOrganizationsComponent,
		TransactionCancelEffectiveDateComponent,
		TransactionCancelReasonsComponent,
		TransactionCancelReinstateCommentsComponent,
		TransactionEndorseIssueDifferencesComponent,
		TransactionEndorseIssueSummaryComponent,
		TransactionEndorseReasonsComponent,
		TransactionEndorseStatusEffectiveDateComponent,
		TransactionRescindCancelComponent,
		TransactionRescindEndorseComponent,
		TransactionDeclineReasonsComponent
	],
	providers: [
		AdjustmentDetailsComponent,
		AdjustmentSummaryComponent,
		AdjustmentWriteOffAmountComponent,
		AdjustmentWriteOffCommentsComponent,
		AssociatedEntityListComponent,
		AssociatedKeyContactsComponent,
		AssociatedOrganizationComponent,
		BindTransactionComponent,
		CommissionDisbursementListComponent,
		CommissionDisbursementListExpandComponent,
		CreateTransactionComponent,
		InsurancePolicySearchComponent,
		InsuranceService,
		{
			provide: InsuranceTokenLookup.tokens.InsuranceService,
			useClass: InsuranceService
		},
		MakePaymentComponent,
		MakePaymentReceiptComponent,
		NextPaymentInstallmentDetailsComponent,
		NonRenewPolicyComponent,
		PaymentBatchApproveComponent,
		PaymentBatchCreateComponent,
		PaymentDetailsComponent,
		RescindNonRenewPolicyComponent,
		SelectDisbursementOrganizationsComponent,
		StatusReasonsComponent,
		StatusReasonsDirective,
		TransactionAccountingDetailsComponent,
		TransactionAssetsComponent,
		TransactionCancelEffectiveDateComponent,
		TransactionCancelReasonsComponent,
		TransactionCancelReinstateCommentsComponent,
		TransactionCoveragesDeductiblesComponent,
		TransactionFormsComponent,
		TransactionEndorseIssueDifferencesComponent,
		TransactionEndorseIssueSummaryComponent,
		TransactionEndorseReasonsComponent,
		TransactionInterestsComponent,
		TransactionReasonsComponent,
		TransactionEndorseStatusEffectiveDateComponent,
		TransactionRescindCancelComponent,
		TransactionRescindEndorseComponent,
		TransactionDeclineReasonsComponent
	]
})

/**
 * A module used to hold insurance route specific
 * logic and display resources.
 *
 * @export
 * @class InsuranceModule
 */
export class InsuranceModule
{
}