/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined available application level
 * emitted events that can be consumed in listening components
 * via the host listener.
 *
 * @export
 * @const AppEventConstants
 */
export const AppEventConstants:
{
	addBanner: string;
	addBannerEvent: string;
	addDrawer: string;
	addDrawerEvent: string;
	addFullScreenBanner: string;
	addFullScreenBannerEvent: string;
	addLoginMessage: string;
	addLoginMessageEvent: string;
	contextMenuActive: string;
	contextMenuActiveEvent: string;
	displayContextOverlay: string;
	displayContextOverlayEvent: string;
	displayDrawerOverlay: string;
	displayDrawerOverlayEvent: string;
	hideAssociatedMenus: string;
	hideAssociatedMenusEvent: string;
	navigateToAccessDenied: string;
	navigateToAccessDeniedEvent: string;
	redrawDashboard: string;
	redrawDashboardEvent: string;
	refreshBadgePromise: string;
	refreshBadgePromiseEvent: string;
	refreshComponent: string;
	refreshComponentEvent: string;
	reloadProductSettings: string;
	reloadProductSettingsEvent: string;
	repeaterItemAltered: string;
	repeaterItemAlteredEvent: string;
	repeaterCleanModel: string;
	repeaterCleanModelEvent: string;
	ruleOverride: string;
	ruleOverrideEvent: string;
	setBadgePromise: string;
	setBadgePromiseEvent: string;
	setContentContext: string;
	setContentContextEvent: string;
	setUtilityMenu: string;
	setUtilityMenuEvent: string;
	siteLayoutChanged: string;
	siteLayoutChangedEvent: string;
	tableExpansionPanelLoaded: string;
	tableExpansionPanelLoadedEvent: string;
	themeChanged: string;
	themeChangedEvent: string;
} = {
	addBanner: 'add-banner-event',
	addBannerEvent: 'window:add-banner-event',
	addDrawer: 'add-drawer-event',
	addDrawerEvent: 'window:add-drawer-event',
	addFullScreenBanner: 'add-full-screen-banner-event',
	addFullScreenBannerEvent: 'window:add-full-screen-banner-event',
	addLoginMessage: 'add-login-message-event',
	addLoginMessageEvent: 'window:add-login-message-event',
	contextMenuActive: 'context-menu-active-event',
	contextMenuActiveEvent: 'window:context-menu-active-event',
	displayContextOverlay: 'display-context-overlay-event',
	displayContextOverlayEvent: 'window:display-context-overlay-event',
	displayDrawerOverlay: 'display-drawer-overlay-event',
	displayDrawerOverlayEvent: 'window:display-drawer-overlay-event',
	hideAssociatedMenus: 'hide-associated-menus-event',
	hideAssociatedMenusEvent: 'window:hide-associated-menus-event',
	navigateToAccessDenied: 'navigate-to-access-denied-event',
	navigateToAccessDeniedEvent: 'window:navigate-to-access-denied-event',
	redrawDashboard: 'redraw-dashboard-event',
	redrawDashboardEvent: 'window:redraw-dashboard-event',
	refreshBadgePromise: 'refresh-badge-promise-event',
	refreshBadgePromiseEvent: 'window:refresh-badge-promise-event',
	refreshComponent: 'refresh-component-event',
	refreshComponentEvent: 'window:refresh-component-event',
	reloadProductSettings: 'reload-product-settings',
	reloadProductSettingsEvent: 'window:reload-product-settings',
	repeaterItemAltered: 'repeater-item-altered-event',
	repeaterItemAlteredEvent: 'window:repeater-item-altered-event',
	repeaterCleanModel: 'repeater-clean-model-event',
	repeaterCleanModelEvent: 'window:repeater-clean-model-event',
	ruleOverride: 'rule-override-event',
	ruleOverrideEvent: 'window:rule-override-event',
	setBadgePromise: 'set-badge-promise-event',
	setBadgePromiseEvent: 'window:set-badge-promise-event',
	setContentContext: 'set-content-context-event',
	setContentContextEvent: 'window:set-content-context-event',
	setUtilityMenu: 'set-utility-menu-event',
	setUtilityMenuEvent: 'window:set-utility-menu-event',
	siteLayoutChanged: 'site-layout-changed-event',
	siteLayoutChangedEvent: 'window:site-layout-changed-event',
	tableExpansionPanelLoaded: 'table-expansion-panel-loaded-event',
	tableExpansionPanelLoadedEvent: 'window:table-expansion-panel-loaded-event',
	themeChanged: 'theme-changed-event',
	themeChangedEvent: 'window:theme-changed-event'
};