/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	CommonDashboardComponent
} from '@shared/components/common-dashboard/common-dashboard.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-coverages-deductibles',
	templateUrl: './transaction-coverages-deductibles.component.html',
	styleUrls: [
		'./transaction-coverages-deductibles.component.scss'
	]
})

/**
 * A component representing an associated company used
 * in the associated entity list.
 *
 * @export
 * @class TransactionCoveragesDeductiblesComponent
 * @implements {IDynamicComponent<Component, {
		entityInstance: IEntityInstance,
		entityListContext: IAssociatedEntityListContext
	}>}
 */
export class TransactionCoveragesDeductiblesComponent
implements IDynamicComponent<Component, {
		entityInstance: IEntityInstance;
		entityListContext: IAssociatedEntityListContext;
	}>, OnInit
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IEntityInstance>}
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public context: IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance;
			entityListContext: IAssociatedEntityListContext;
		}>;

	/**
	 * Gets or sets the data object used in this component.
	 *
	 * @type {any[]}
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public data: any[];

	/**
	 * Initiates the component to get the data object.
	 *
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNullOrEmpty(
			this.context.data.entityInstance)
			|| AnyHelper.isNullOrEmpty(
				this.context.data.entityInstance.data?.assets))
		{
			return;
		}

		this.setData();

		if (!AnyHelper.isNull(
			(<CommonDashboardComponent>this.context.source).finishedLoading))
		{
			(<CommonDashboardComponent>this.context.source)
				.finishedLoading.emit(
					true);
		}
	}

	/**
	 * Sets the data object used in this component.
	 *
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public setData(): void
	{
		const assets: any = this.context.data.entityInstance.data.assets;

		this.data = assets.filter(
			(item: any) =>
				item?.type === 'Dwelling');

		const formatter =
			new Intl.NumberFormat(
				'en-US',
				{
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 0
				});

		this.data.forEach((item: any) =>
		{
			for (const limit of Object.keys(item.characteristics.limits))
			{
				item.characteristics.limits[limit] =
					(parseInt(
						item.characteristics.limits[limit],
						AppConstants.parseRadix) <= 1)
						? `${item.characteristics.limits[limit] * 100}%`
						: `${formatter.format(
							item.characteristics.limits[limit])}`;
			}

			for (const deductible of Object.keys(
				item.characteristics.deductibles))
			{
				item.characteristics.deductibles[deductible] =
					(parseInt(
						item.characteristics.deductibles[deductible],
						AppConstants.parseRadix) <= 1)
						? `${item.characteristics
							.deductibles[deductible] * 100}%`
						: `${formatter.format(
							item.characteristics.deductibles[deductible])}`;
			}
		});
	}
}